import React, {useEffect, useState} from 'react'
import { RxCross2 } from "react-icons/rx";

export type AlertType = 'info' | 'error' | 'warning' | 'success' | 'dark'

export interface AlertProps {
    isVisible: boolean;
    hideAlert: () => void;

    autoClose?: boolean;
    showCloseButton?: boolean;
    type?: AlertType;

    message: string | JSX.Element;
    className?: string;
    zIndex?:number;
    position?: string;
}


export const Alert = ({
    isVisible,
    hideAlert,
    
    autoClose=true,
    showCloseButton=true,
    type='error',

    message='',
    className,
    zIndex=-1,
    position='absolute'
}:AlertProps) => {

    // hide banner timeout
    useEffect(() => {
        if (isVisible && autoClose && hideAlert) {
            const timer = setTimeout(() => {
                hideAlert()
            }, 3000);
            // Clear the timer on component unmount or if the state changes before 5 seconds
            return () => clearTimeout(timer)
        }
    },[isVisible, autoClose, hideAlert])




    const bannerColor = 
        type==='info' ? 'bg-blue-50' :
        type==='error' ? 'bg-red-50' :
        type==='warning' ? 'bg-yellow-50' :
        type==='success' ? 'bg-green-100' :
        type==='dark' ? 'bg-gray-50' :
        'bg-red-50'

    const textColor = 
        type==='info' ? 'text-blue-800' :
        type==='error' ? 'text-red-800' :
        type==='warning' ? 'text-yellow-800' :
        type==='success' ? 'text-green-800' :
        type==='dark' ? 'text-gray-800' :
        'text-red-800'


    return (
        <div className={` ${position} top-0 left-0 w-full flex flex-row items-center justify-center
            alert ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-100%]'} transition-all duration-300 ease-in-out
            ${bannerColor} ${className}
        `}
        style={{zIndex:zIndex}}>
            <div role="alert" className={`
                p-4 text-sm w-full flex justify-center
                ${textColor} 
            `}>
                {message}
                
            </div>
            
            {   showCloseButton ? (
                <div 
                    className="ml-0 right-0 mr-3 cursor-pointer"
                    onClick={hideAlert}
                >
                    <RxCross2 size={24} className={`${textColor}`}/>
                </div>
            ) : (<></>)
            }
        </div>
    )
}