


import { useAppDispatch } from '@/src/hooks/useDispatch';
import { userLogout } from '@/store/slices/userSlice';
import { resetBookmark } from '@/store/slices/bookmarkSlice';
import { resetOrganizerSlice } from '@/store/slices/organizer-slice/organizerSlice';
import { signOut } from "next-auth/react"



export const useLogout = () => {
    const dispatch = useAppDispatch();

    const logout = async () => {
        signOut()
        dispatch(userLogout())
        dispatch(resetBookmark())
        dispatch(resetOrganizerSlice())
    }
    return logout
}

