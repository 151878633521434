import React, {useState, useEffect} from 'react'
import Image from 'next/image'
import { TiUserOutline } from "react-icons/ti";


import { BouncingLoader } from './loader/BouncingLoader';


export interface ProfilePictureIconProps {
    height?: string;
    image?: File | Blob | null | string;
    alt?: string;
    className?: string;
    isPriority?: boolean;
    isLoading?: boolean;
    placeholderSize?: number;
}

export const ProfilePictureIcon = ({
    height='h-[48px]',
    image=null,
    alt='Profile Picture',
    className,
    isPriority=true,
    isLoading=false,
    placeholderSize=32
}:ProfilePictureIconProps) => {
    

    /**
     * IMAGE & IMAGE URL
    */
    // const [isPortrait, setIsPortrait] = useState<boolean | null>(true)
    const [imageUrl, setImageUrl] = useState('')
    useEffect(() => {
        if (image && typeof image!=='string') {
            setImageUrl(URL.createObjectURL(image))
        } else {
            setImageUrl('')
        }
    },[image])

    return (
        <div
            className={`
                ${height} aspect-square
                rounded-full flex items-center justify-center border
                ${className}
            `}
        >
            <div 
                style={{width: '100%', height: '100%', position: 'relative'}}
                className='flex items-center justify-center bg-white rounded-full overflow-hidden'
            >
                {   (isLoading) ? (
                    <BouncingLoader className='mt-3'/>
                ) : (imageUrl || (typeof image=='string' && image!='')) ? (
                    <Image
                        src={typeof image=='string' ? `${image}` : imageUrl} 
                        alt={alt}
                        style={{ objectFit: 'cover' }}
                        fill
                        sizes="(max-width: 768px) 100vw, 
                            (max-width: 1200px) 50vw, 
                            33vw"
                        priority={isPriority}
                    />
                ) : (
                    <TiUserOutline size={placeholderSize} className='text-gray-400'/>
                )}
            </div>
        </div>
    )
}
