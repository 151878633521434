//packages
import React,{useEffect, useState} from 'react';
import Link from 'next/link'
import { useRouter } from 'next/router';

import { useMediaQuery } from 'react-responsive';

import { TbMessage } from "react-icons/tb";


//atoms
import { HeaderLogo } from '@/src/atoms/logo/headerLogo';
import { Button } from '@/src/atoms-new/Button';
import { Alert } from '@/src/atoms/alert';
// import
import { ProfilePictureIcon } from '@/src/atoms-new/ProfilePictureIcon';

//header components
import { NotificationDropdown } from '@/src/components/dropdowns/notification-dropdown/NotificationDropdown';
import { LanguageDropdown } from '@/src/components-new/dropdown/language-dropdown/LanguageDropdown';
import { UserDropdownMenu } from './UserDropdownMenu';
import { GuestDropdownMenu } from './GuestDropdownMenu';

//redux
import { useAppSelector } from '@/src/hooks/useSelector';


interface UserHeaderProps    {
    // l?: 'en'|'jp';

}
export const UserHeader = ({
    // l='jp'

}: UserHeaderProps) => {
    const router = useRouter();
    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    //responsive
    const smallScreen = useMediaQuery({ maxWidth:640 })
    const isMobile = useMediaQuery({ maxWidth:768 })
    const isBigScreen = useMediaQuery({ minWidth:1024 })


    const userData = useAppSelector(state => state.user).data
    const {user, user_profile} = userData
    const {token, is_organizer, profilePicture} = user
    // const {profilePicture} = user_profile

    // user type: guest, user, or organizer
    const isUser = token ? true : false
    const isOrganizer = isUser && is_organizer ? true : false


    /**
     *  SEARCH LESSON & ORGANIZER BUTTONS
    */    
    const searchLessonHandler = () => {
        router.push('/search-lessons')
    }
    const searchOrganizerHandler = () => {
        router.push('/search-organizers')
    }


    /**
     * Success Banner state
    */
    const [successBannerText, setSuccessBannerText] = useState('')


    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        lesson:{
            en:'Search Dance Lessons',
            jp:'ダンスレッスン検索'
        },
        organizer:{
            en:'Organizers',
            jp:'主催者'
        },
        switch:{
            en:"Switch to Organizer",
            jp:'主催者アカウントに切り替える'
        },
        teach:{
            en:"Become an Organizer",
            jp:'教えたい方はこちらから'
        },
        login:{
            en:'Login',
            jp:'ログイン'
        },
        signup:{
            en:'Create Account',
            jp:'新規登録'
        }
    }


    return (
        <div>
            <div className={`sticky top-0 bg-white w-full h-[50px] md:h-[70px] drop-shadow-sm flex items-center relative`}>
                <nav className='flex flex-row h-full w-full justify-between items-center '>
                    <div className='justify-self-start pl-3'>
                        <Link href='/'>
                            <HeaderLogo />
                        </Link>
                    </div>

                    {/** Middle (large screen only) */}
                    <div className=' justify-self-center hidden md:flex flex-row gap-2 sm:gap-10'>
                        <div className='flex items-center' 
                            onClick={searchLessonHandler}
                        >
                            <p 
                                className='block text-gray-700 hover:text-gray-900 cursor-pointer font-semibold text-sm md:text-[16px]'
                                >{text['lesson'][l]}
                            </p>
                        </div>
                        <div className='flex items-center hidden' 
                            onClick={searchOrganizerHandler}
                        >
                            <p 
                                className='block text-gray-700 hover:text-gray-900 cursor-pointer font-semibold text-sm md:text-[16px]'
                                >{text['organizer'][l]}
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-row gap-3 mr-2 md:mr-3 items-center justify-self-end'>
                        {/** big screen only */}
                        {   isOrganizer ? (
                            <Link href='/organizer/dashboard/home' className='cursor-pointer hidden lg:block'>
                                <p className='text-sm text-gray-700 md:text-[16px]'>{text['switch'][l]}</p>
                            </Link>
                        ) : (
                            <Link href='/join-ambition/organizer' className='cursor-pointer hidden lg:block'>
                                <p className='text-sm text-gray-700 md:text-[16px]'>{text['teach'][l]}</p>
                            </Link>
                        )}

                        {  (isUser && isBigScreen) ? (
                            <>
                                {/** is loggedin, big screen */}
                                <NotificationDropdown />

                                <UserDropdownMenu 
                                    Button={(
                                        <ProfilePictureIcon 
                                            height='h-[40px] md:h-[44px]'
                                            className='cursor-pointer hover:shadow-md'
                                            image={profilePicture}
                                        />
                                    )}
                                />
                            </>
                        ) : (isUser && !isBigScreen) ? (
                            <>
                                {/** is loggedin, big screen */}
                                <NotificationDropdown />
                                {   !isMobile ? (
                                    <UserDropdownMenu 
                                        Button={(
                                            <ProfilePictureIcon 
                                                height='h-[40px] md:h-[44px]'
                                                className='cursor-pointer hover:shadow-md'
                                                image={profilePicture}
                                            />
                                        )}
                                    />
                                ) : (
                                    <div className='mr-3 text-gray-600 cursor-pointer hover:text-gray-800' onClick={() => router.push('/user/messages')}>
                                        <TbMessage size={28} className=''/>
                                    </div>

                                )}
                                
                            </>

                        ) : (!isUser && isBigScreen) ? (
                            <div className='flex flex-row gap-3 pr-3 items-center'>
                                <LanguageDropdown />
                                
                                <div className='flex items-center' onClick={() => router.push('/auth/login')}>
                                    <p className='block text-gray-700 hover:text-gray-900 cursor-pointer font-semibold text-sm md:text-[16px]'>
                                        {text['login'][l]}
                                    </p>
                                </div>

                                <div className='h-[44px] w-fit flex'>
                                    <Button 
                                        color='bg-prime-orange-normal hover:bg-prime-orange-dark'
                                        textColor='text-white'
                                        borderColor=''
                                        // bgType='none'
                                        onClick={() => router.push('/auth/signup')}
                                        ><p className=' text-sm md:text-[16px]'>{text['signup'][l]}</p>
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className='flex flex-row gap-3 pr-3 items-center'>
                                <GuestDropdownMenu />
                            </div>
                        )}
                    </div>
                </nav>
            </div>
            
            {/**BANNER GOES HERE */}
            <div className={successBannerText ? 'relative z-[-1]' : 'hidden'}>
                <Alert 
                    isVisible={successBannerText ? true : false}
                    hideAlert={() => setSuccessBannerText('')}
                    
                    message={successBannerText}
                    type='success'
                    showCloseButton={true}
                    autoClose={false}
                    zIndex={-1}
                />
            </div>

        </div>
    )
}
