import React, {useState, useEffect, useMemo} from 'react';

import { useMediaQuery } from 'react-responsive';

interface HeaderLogoProps {
    type?: 'user' | 'instructor' | 'studio',
    frontColor?: 'orange' | 'blue' | 'white' | 'black',
    backColor?: 'orange' | 'blue' | 'white' | 'black',
}

export const HeaderLogo = ({
    type='user',
    frontColor='orange', 
    backColor='white',
    
}: HeaderLogoProps) => {

    //responsive
    const smallScreen = useMediaQuery({ maxWidth:480 })

    const textColor: string = 
        frontColor=='orange' ? 'text-prime-orange-normal' : 
        frontColor=='blue' ? 'text-[#245BDF]' : 
        frontColor=='white' ? 'text-prime-white' : 
        frontColor=='black' ? 'text-prime-dark' : 
        'text-prime-orange-normal'

    // device width && logo text
    // const logoText = useMemo(() => (
    //     !smallScreen ? 'AMBITION' : "A"
    // ),[smallScreen])
    const logoText = "AMBITION"
    

    return (
        <div className={`flex flex-col relative ${smallScreen ? 'items-center' : 'items-end'} mt-2`}>
            <p 
                className={` text-black
                ${smallScreen ? 'text-2xl' : 'text-3xl'}
                `}
                style={{ fontFamily: 'G-Knewave' }}
            >
                {logoText}
            </p>
            <p 
                className={`${textColor} absolute -top-0.5 -left-0.5
                ${smallScreen ? 'text-2xl' : 'text-3xl'}
                `}
                style={{ fontFamily: 'G-Knewave'}}
            >
                {logoText}
            </p>

            {   type == 'instructor' ? <p className='text-xs font-bold -translate-y-2'>instructor</p> : 
                type == 'studio' ? <p className='text-xs font-bold -translate-y-2'>studio</p> : 
                <></>
            }
        </div>
    )
}